<template>
  <div class="bet-ticker-client-select">
    <div class="bet-ticker-client-select__form-group">
      <div class="bet-ticker-client-select__form-label">
        Client
      </div>
      <div
        ref="clientSelectRootElement"
        class="bet-ticker-client-select__select"
      >
        <div
          :class="[
            'bet-ticker-client-select__select-field',
            { 'bet-ticker-client-select__select-field--is-active': isClientsMenuOpen },
          ]"
          @click="toggleClientsMenu"
        >
          <div class="bet-ticker-client-select__select-field-value">
            <span class="bet-ticker-client-select__select-field-label">
              {{ selectedClientsLabel }}
            </span>
            <span class="bet-ticker-client-select__select-field-suffix">
              {{ selectedClientLabelSuffix }}
            </span>
          </div>
          <Icon
            class="bet-ticker-client-select__select-field-remove"
            name="x"
            @click.stop="removeClient"
          />
        </div>
        <div
          v-if="isClientsMenuOpen"
          class="bet-ticker-client-select__select-menu"
        >
          <div
            v-for="operator in operatorsList"
            :key="operator.operatorId"
            class="bet-ticker-client-select__select-item"
            @click="toggleClient(operator)"
          >
            <Checkbox
              :model-value="isClientSelected(operator)"
              @update:model-value="toggleClient(operator)"
            />
            <span>
              {{ operator.operatorId }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  cloneDeep,
  includes,
  map,
  filter,
} from 'lodash';
import {
  ref,
  computed,
} from 'vue';
import { useStore } from 'vuex';
import { onClickOutside } from '@vueuse/core';
import Icon from '@/components/common/Icon';
import Checkbox from '@/components/common/Checkbox';

export default {
  components: {
    Icon,
    Checkbox,
  },
  props: {
    filter: {
      type: Object,
      required: true,
    },
  },
  emits: {
    'update:filter': {
      type: Object,
    },
    removeFilter: {
      type: Object,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const currentFilter = ref(cloneDeep(props.filter));
    const syncCurrentFilter = () => {
      const updatedFilter = cloneDeep(currentFilter.value);
      emit('update:filter', updatedFilter);
      currentFilter.value = updatedFilter;
    };

    const clientSelectRootElement = ref(null);
    const isClientsMenuOpen = ref(false);
    const isClientsLoading = ref(false);
    const allClients = ref([]);
    const clientSearch = ref('');

    const selectedClients = computed(() => currentFilter.value?.parameter?.clients);
    const selectedClientsLabel = computed(() => {
      const { operatorId = 'N/A' } = selectedClients.value?.[0] || {};
      return operatorId;
    });
    const selectedClientLabelSuffix = computed(() => {
      const lengthWithoutFirst = (selectedClients.value?.length || 1) - 1;
      return lengthWithoutFirst > 0 ? ` +${lengthWithoutFirst}` : '';
    });
    const operatorsList = computed(() => store.getters.operatorsList);

    const isClientSelected = ({ operatorId }) => includes(map(selectedClients.value, 'operatorId'), operatorId);
    const toggleClient = (client) => {
      if (isClientSelected(client)) {
        currentFilter.value.parameter.clients = filter(
          currentFilter.value.parameter.clients,
          ({ operatorId }) => client.operatorId !== operatorId,
        );
      } else {
        currentFilter.value.parameter.clients = [
          ...currentFilter.value.parameter.clients,
          client,
        ];
      }
    };

    const removeClient = () => {
      isClientsMenuOpen.value = false;
      emit('removeFilter', props.filter);
    };

    const toggleClientsMenu = () => {
      if (isClientsMenuOpen.value) syncCurrentFilter();
      isClientsMenuOpen.value = !isClientsMenuOpen.value;
    };

    onClickOutside(clientSelectRootElement, () => {
      if (isClientsMenuOpen.value) {
        isClientsMenuOpen.value = false;
        syncCurrentFilter();
      }
    });

    return {
      currentFilter,
      isClientsMenuOpen,
      clientSelectRootElement,
      selectedClients,
      selectedClientsLabel,
      selectedClientLabelSuffix,
      isClientsLoading,
      allClients,
      clientSearch,
      isClientSelected,
      toggleClient,
      removeClient,
      toggleClientsMenu,
      operatorsList,
    };
  },
};
</script>

<style lang="scss">
.bet-ticker-client-select {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  &__form-group {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
  }

  &__form-label {
    width: 100%;
    color: #6D6D6D;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 600;
    width: 100%;
  }

  &__select {
    position: relative;
    width: 100%;

    &--is-disabled {
      .bet-ticker-client-select__select-field {
        background-color: #F0F0F0;
        color: #A9A9A9;
        cursor: not-allowed;
      }
    }
  }

  &__select-field {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border: 1px solid #F0F0F0;
    border-radius: 4px;
    padding: 12px;
    width: 100%;
    height: 32px;
    gap: 8px;
    cursor: pointer;
    position: relative;

    &--is-active {
      outline: 1px solid #a2bfff;
    }

    &-value {
      display: flex;
      align-items: center;
      gap: 4px;
      overflow: hidden;
    }

    &-label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-remove {
      flex-shrink: 0;

      svg {
        stroke: #191414;
      }
    }
  }

  &__select-menu {
    position: absolute;
    top: 100%;
    right: 0;
    width: 100%;
    max-height: 230px;
    overflow-y: auto;
    z-index: 9000;
    display: flex;
    flex-direction: column;
    border-radius: var(--radius-medium, 4px);
    border: 1px solid var(--neutral-stroke-default-resting, #F0F0F0);
    background: var(--neutral-bg-default-resting, #FFF);
    box-shadow: 0px 2px 4px 0px rgba(25, 20, 20, 0.08);
    margin-top: 4px;
    padding: 4px 0;
    user-select: none;
  }

  &__select-item {
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    height: 32px;
    padding: 8px;

    &:hover,
    &:focus {
      background-color: #FAFAFA;
      outline: none;
    }

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .radio-input,
    .checkbox {
      width: 14px;
      height: 14px;
    }
  }

  &__select-search {
    background-color: inherit;
    position: sticky;
    top: 0;
    padding: 8px;
  }
}
</style>
